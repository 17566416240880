import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Earn from 'pages/earn';
import Exchange from 'pages/exchange';
import Home from 'pages/home';
import Practice from 'pages/practice';
import PranaShop from 'pages/prana-shop';
import RewardHistory from 'pages/reward-history';
import Wallet from 'pages/wallet-v2';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'shared/components/layout/layout';
import { envResolver } from 'shared/configs/env-resolver';
import Campaign from 'pages/campaign/campaign';
import { AcademyQuestion } from 'pages/academy-question';
// import Telegram from 'shared/utils/telegram';
import {
	useEffect,
	//  useRef
} from 'react';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import Vip from 'pages/vip';
import AvatarShop from 'pages/avatar-shop';
import MapPage from 'pages/map';
import AcademyChooseTopic from 'pages/academy-choose-topic';
import AcademyMatch from 'pages/academy-match';
import { initializeConnection } from 'store/reducers/signalR';
import Telegram from 'shared/utils/telegram';

const manifestUrl = envResolver.TON_MANIFEST;

const App = () => {
	// const originUrl = window.location.origin;
	const { userId } = Telegram().getTelegramData();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(initializeConnection(`https://wss.pineye.net/gameHub?chatid=${userId}`));
	}, [dispatch]);

	return (
		<TonConnectUIProvider
			actionsConfiguration={{
				twaReturnUrl: 'https://t.me/pineye_bot',
			}}
			manifestUrl={manifestUrl}
		>
			<BrowserRouter>
				<Toaster
					position='top-center'
					containerStyle={{
						zIndex: 9999999999,
					}}
					containerClassName='toaster_container'
				/>
				<Layout>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/earn' element={<Earn />} />
						<Route path='/reward-history' element={<RewardHistory />} />
						<Route path='/wallet' element={<Wallet />} />
						<Route path='/exchange' element={<Exchange />} />
						<Route path='/prana/shop' element={<PranaShop />} />
						<Route path='/games/prana/practice/:id' element={<Practice />} />
						<Route path='/campaign' element={<Campaign />} />
						<Route path='/vip' element={<Vip />} />
						<Route path='/avatar-shop' element={<AvatarShop />} />
						<Route path='/map' element={<MapPage />} />
						<Route path='/academy/choose' element={<AcademyChooseTopic />} />
						<Route path='/academy/match/:topic' element={<AcademyMatch />} />
						<Route path='/academy/question/:topic' element={<AcademyQuestion />} />
					</Routes>
				</Layout>
			</BrowserRouter>
		</TonConnectUIProvider>
	);
};

export default App;
