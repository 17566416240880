import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CharacterContainer = styled.section(
	() => css`
		position: relative;
		.char {
			width: 300px;
			position: absolute;
			top: -260px;
			inset-inline-end: 8%;
			z-index: 2;
		}
		.char_with_pet {
			width: 300px;
			position: absolute;
			top: -260px;
			inset-inline-end: 15%;
			z-index: 2;
		}
		.pet {
			width: 300px;
			position: absolute;
			top: -280px;
			inset-inline-end: 5%;
			z-index: 1;
		}
		@media (max-width: 350px) {
			.char {
				width: 250px;
				top: -230px;
				inset-inline-end: 11%;
			}
			.char_with_pet {
				width: 250px;
				top: -230px;
				inset-inline-end: 12%;
				z-index: 2;
			}
			.pet {
				width: 220px;
				top: -230px;
				inset-inline-end: 10%;
			}
		}
	`,
);
