import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AcademyQuestionContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		background: #000d20;
		position: fixed;
		top: 0;
		inset-inline-start: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 100%;
		h1 {
 			text-align: center;
			top: 30%;
			position: fixed;
			top: 40vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			border: 3px solid #edb793;
			border-radius: 10px;
			padding: 43px;
			width: 92%;
			background: #00000085;
		}
		.bg {
			position: absolute;
			top: 0;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			width: 100%;
			object-fit: cover;
			aspect-ratio: 1170/2634;
		}

		/* header */
		.header {
			position: fixed;
			top: 2vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			display: grid;
			grid-template-columns: 115px 115px;
			justify-content: space-between;
			width: 100%;
			padding-inline-start: 16px;
			padding-inline-end: 16px;
			.left,
			.right {
				display: flex;
				flex-direction: column;
				position: relative;
				p {
					text-align: center;
				}
				.score {
					background: #000;
					border-radius: 17px;
					padding: 6px 14px;
					align-self: flex-end;
					font-size: 12px;
					font-weight: bold;
					line-height: 14.86px;
					text-align: end;
					align-items: center;
					width: 100%;
				}
				img {
					position: absolute;
					top: 0;
					width: 48px;
					height: 58px;
				}
			}
			.left {
				img {
					inset-inline-start: -10px;
				}
			}
			.right {
				.score {
					align-self: flex-start;
					text-align: end;
					direction: rtl;
				}
				img {
					inset-inline-end: -10px;
				}
			}
		}
		.answers_check {
			position: fixed;
			top: 18vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			display: grid;
			grid-template-columns: auto auto;
			align-items: center;
			justify-content: space-between;
			gap: 5px;
			width: 100%;
			.ac_left {
				padding-inline-start: 16px;
				display: flex;
				justify-content: flex-start;
				gap: 1px;
				img {
					width: 30px;
				}
			}
			.ac_right {
				padding-inline-end: 16px;
				display: flex;
				justify-content: flex-end;
				gap: 1px;
				img {
					width: 30px;
				}
			}
		}
		.question {
			position: fixed;
			top: 40vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			border: 3px solid #edb793;
			border-radius: 10px;
			padding: 43px;
			width: 92%;
			background: #00000085;
			font-size: 15px;
			font-weight: bold;
			line-height: 18.57px;
			letter-spacing: 0.08em;
			text-align: center;

			.title {
				position: absolute;
				top: 0;
				inset-inline-start: 50%;
				transform: translateX(-50%) translateY(-50%);
				border: 1.52px solid #edb793;
				border-radius: 8px;
				padding: 10px;
				width: 35%;
				box-shadow: 0px 3.04px 3.04px 0px #00000066;
				background: #0b1217;
				font-size: 14px;
				font-weight: bold;
				line-height: 17.33px;
				letter-spacing: 0.08em;
				text-align: center;
			}
			.timer {
				position: absolute;
				bottom: 0;
				inset-inline-start: 50%;
				transform: translateX(-50%) translateY(50%);
				background-image: url(/img/academy/timer_bg.webp);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				width: 70%;
				height: 7.5vw;

				.border {
					position: absolute;
					top: 2vw;
					inset-inline-start: 50%;
					transform: translateX(-50%);
					width: 72%;
					height: 14px;
					border-radius: 13px;
					background: transparent;
					box-shadow: 0px 2px 4px 0px #ffffffc2 inset, 0px 0px 6px 0px #000000;
				}

				.filled {
					height: 100%;
					border-radius: 12px;
					width: 0;
					transition: all 300ms ease;
					position: relative;
					background: #09a93b;
					box-shadow: 0px 2px 4px 0px #ffffff inset, 0px 4px 4px 0px #002fec24;
				}
			}
		}
		.answer_box {
			position: fixed;
			top: 85vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 10px;
			width: 100%;
			.answer_item {
				background-image: url(/img/academy/a_bg.webp);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				width: 100%;
				height: 55px;
				text-align: center;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 13px;
				font-weight: bold;
				line-height: 16.09px;
				letter-spacing: 0.03em;
			}
			.answer_item_correct {
				background-image: url(/img/academy/g_bg.webp);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				width: 100%;
				height: 55px;
				text-align: center;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 13px;
				font-weight: bold;
				line-height: 16.09px;
				letter-spacing: 0.03em;
			}
			.answer_item_wrong {
				background-image: url(/img/academy/r_bg.webp);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				width: 100%;
				height: 55px;
				text-align: center;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 13px;
				font-weight: bold;
				line-height: 16.09px;
				letter-spacing: 0.03em;
			}
		}
	`,
);
