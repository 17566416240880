import { useEffect } from 'react';
import { ShopPranaContainer } from './css';
import { PracticeCard } from './components';
// import numberUtils from 'shared/utils/number';
// import { useAppSelector } from 'shared/hooks/redux-helper';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { flush, injectGlobal } from '@emotion/css';
import { Overview } from 'pages/home/components';
import { useGetAvatarsQuery } from 'store/query';

const PranaShop = () => {
	const navigate = useNavigate();
	const { data } = useGetAvatarsQuery();

	useEffect(() => {
		injectGlobal`
			body,nav{
 				background: #00001c;
			}
		`;
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/');
		});
		return () => {
			flush();
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);

	return (
		<ShopPranaContainer>
			<Helmet>
				<title>PinEye | Prana Shop</title>
			</Helmet>
			<div className='bottom_light' />
			<img src='/img/prana/header.webp' alt='' className='banner' />
			{data && <Overview name={data?.displayName} />}
			<div className='header'></div>

			<PracticeCard />
		</ShopPranaContainer>
	);
};
export default PranaShop;
