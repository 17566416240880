import { FC } from 'react';
import { OverviewContainer } from '../css';
import numberUtils from 'shared/utils/number';
import { useGetAvatarsQuery } from 'store/query';
import { useNavigate } from 'react-router-dom';

interface IPropsOverview {
	name: string;
}
const Overview: FC<IPropsOverview> = ({ name }) => {
	const { data } = useGetAvatarsQuery();
	const navigate= useNavigate();
	return (
		<OverviewContainer>
			<div className='name_container'>
				<div className='name'>
					<img src='/img/avatar-shop/head.webp' alt=' ' className='head' />
					<p>{name}</p>
				</div>
			</div>
			<div className='tech' onClick={()=>navigate('/academy/choose')}>
				<img src='/img/home-avatar/top-tech.webp' alt='' className='icon' />
				<p>Education</p>
				<span>{numberUtils.formatPriceWithDecimals(data?.score?.education ?? 0)}</span>
			</div>
			<div className='gem_container'>
				<div className='name'>
					<img src='/img/avatar-shop/diamond.webp' alt=' ' className='head' />
					<p>{numberUtils.formatPriceWithDecimals(data?.score?.diamond ?? 0)}</p>
				</div>
			</div>
			<div className='line' />
		</OverviewContainer>
	);
};
export default Overview;
