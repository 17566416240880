import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { ButtonContainer } from './button.style';
import DotLoader from '../dot-loader';

const ButtonNew: FC<
	{
		className?: string;
		variant: 'primary' | 'secondary' | 'thirty';
		children: ReactNode;
		isLoading?: boolean;
		disabled?: boolean;
		onClick?: (e: { preventDefault(): void; target: unknown }) => void;
	} & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, variant, className, isLoading, disabled, onClick }) => {
	return (
		<ButtonContainer
			className={`${variant} ${className ?? ''} ${disabled ? 'disabled' : ''}`}
			disabled={disabled !== undefined ? disabled : isLoading === true ? true : undefined}
			onClick={onClick}
		>
			{isLoading ? <DotLoader /> : children}
		</ButtonContainer>
	);
};

export default ButtonNew;
