import { useEffect, useState } from 'react';
import { AcademyQuestionContainer } from './css';
import ResultModal from './result-modal';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { leaveAcademy, playerAnswer, sendNewquestion } from 'store/signalRActions';
import Telegram from 'shared/utils/telegram';
import { useGetAvatarsQuery } from 'store/query';
import numberUtils from 'shared/utils/number';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IPlayer, IResult, IQuestionContent, IAnswerResult } from 'store/types';

// {
// 	"event": "GameStarted",
// 	"data": {
// 	  "gameId": "020c0d02-c2aa-444e-9685-eb2a25c6177d",
// 	  "player1": {
// 		"userId": "111",
// 		"armor": "",
// 		"score": {
// 		  "diamond": 0,
// 		  "education": 0,
// 		  "food": 0
// 		}
// 	  },
// 	  "player2": {
// 		"userId": "222",
// 		"armor": "",
// 		"score": {
// 		  "diamond": 0,
// 		  "education": 0,
// 		  "food": 0
// 		}
// 	  }
// 	},
// 	"messages": []
//   }

interface IMachedUser {
	userId: string;
	armor: string;
	displayName: string;
	score: {
		diamond: number;
		education: number;
		food: number;
	};
}
interface IGameStartData {
	gameId: string;
	player1: IMachedUser;
	player2: IMachedUser;
}
const AcademyQuestion = () => {
	const dispatch = useAppDispatch();
	const { data } = useGetAvatarsQuery();

	const [width, setWidth] = useState('100%');
	const [modal, setModal] = useState(false);
	const [me, setMe] = useState<IPlayer>();
	const [machedUser, setMachedUser] = useState<IMachedUser>();
	const navigate = useNavigate();
	// Current question data
	const [q, setQ] = useState('');
	const [choices, setChoices] = useState<IQuestionContent['newQuestion']['choices'] | null>();
	const [gameId, setGameId] = useState<string>();
	const [qId, setQId] = useState<number>();
	const [qIndex, setQIndex] = useState<number>(-1);
	const [endTime, setEndTime] = useState('');

	// Tracks whether user got each question correct or not.
	// Key = question index from server, Value = true/false.
	const [userAnswers, setUserAnswers] = useState<Record<number, boolean | null>>({});
	const [machedUserAnswers, setMachedUserAnswers] = useState<Record<number, boolean | null>>({});

	// Tracks which choice user *just* selected, and the correct answer for the current question
	const [selectedAnswer, setSelectedAnswer] = useState(-1);
	const [correctAnswer, setCorrectAnswer] = useState(-1);
	const [loader, setLoader] = useState<boolean>(true);

	// Optional: track which choice each question used
	// const [selectedAnswers, setSelectedAnswers] = useState<Record<number, number>>({});

	const { userId } = Telegram().getTelegramData();
	const incomingMessage = useSelector((state: RootState) => state.signalR);
	const topic = window.location.pathname.split('/').pop();

	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			dispatch(leaveAcademy());
			navigate('/academy/choose');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);

	// Listen for new question or question answer events
	useEffect(() => {
		if (incomingMessage && incomingMessage?.data.data !== null && incomingMessage?.data?.event === 'GameStarted') {
			const inner = incomingMessage?.data?.data;
			const gameData = inner as unknown as IGameStartData;
			setMachedUser(gameData.player1.userId === userId.toString() ? gameData.player1 : gameData.player2);
		}
		if (incomingMessage && incomingMessage?.data.data !== null && incomingMessage?.data?.event === 'NewQuestion') {
			setLoader(false);
			const inner = incomingMessage?.data?.data;
			const questionContent = inner?.questionContent as IQuestionContent;

			setGameId(inner.gameId as string);
			setQId(questionContent.newQuestion.id);
			setQIndex(questionContent.newQuestion.index);
			setChoices(questionContent.newQuestion.choices);
			setQ(questionContent.newQuestion.text);
			setEndTime(questionContent.newQuestion.endTime);

			// Reset “per-question” states
			setSelectedAnswer(-1);
			setCorrectAnswer(-1);
		}

		if (incomingMessage && incomingMessage?.data.data !== null && incomingMessage?.data?.event === 'QuestionAnswer') {
			const questionAnswerResult = incomingMessage?.data?.data as unknown as IAnswerResult;

			// Was this answer from me?
			if (+questionAnswerResult.userId === userId) {
				// Mark correct or not in userAnswers
				setUserAnswers(prev => ({
					...prev,
					[qIndex]: questionAnswerResult.isCorrect,
				}));
				setCorrectAnswer(questionAnswerResult.correctIndex);
			} else {
				// Or from the other user (matched user)
				setMachedUserAnswers(prev => ({
					...prev,
					[qIndex]: questionAnswerResult.isCorrect,
				}));
			}
		}

		//end game
		if (incomingMessage && incomingMessage?.data.data !== null && incomingMessage?.data?.event === 'EndGame') {
			setModal(true);
		}
		//result
		if (incomingMessage && incomingMessage?.data.data !== null && incomingMessage?.data?.event === 'GameResult') {
			console.log(incomingMessage?.data?.data, 'data- result');
			const inner = incomingMessage?.data?.data;
			const result = inner.gameResult as IResult['gameResult'];
			setMe(+result.player1.userId === userId ? result.player1 : result.player2);
		}
		//rematching
		if (incomingMessage && incomingMessage?.data.data !== null && incomingMessage?.data?.event === 'ReMatching') {
			if (incomingMessage?.data?.messages && incomingMessage?.data?.messages.length > 0) {
				toast.success(incomingMessage.data.messages[0]);
				navigate('/academy/choose');
			}
		}
	}, [incomingMessage.data.data, userId, qIndex]);

	// Timer effect: when we get a new question, wait until endTime, then automatically request next.
	useEffect(() => {
		if (!qId || !gameId || !choices) return;
		if (!endTime) return;

		const endTimets = +endTime * 10000;
		const now = Date.now();

		if (now >= endTimets) {
			// Already expired
			console.log('Question is expired immediately');
			setQ('');
			setChoices(null);
			setSelectedAnswer(-1);
			setCorrectAnswer(-1);
			setWidth('100%');
			dispatch(sendNewquestion({ gameId }));
		} else {
			// Not expired yet: set a timer to dispatch after it expires
			const timerId = setTimeout(() => {
				console.log('Question expired (timer). Requesting next...');
				// Clear current question
				setQ('');
				setChoices(null);
				setSelectedAnswer(-1);
				setCorrectAnswer(-1);
				setWidth('100%');
				dispatch(sendNewquestion({ gameId }));
			}, 15000);

			return () => clearTimeout(timerId);
		}
	}, [endTime, gameId, qId, choices, dispatch]);

	// When user clicks a choice
	const onClickChoice = async (answerIndex: number) => {
		setSelectedAnswer(answerIndex);
		// setSelectedAnswers((prev) => ({ ...prev, [qIndex]: answerIndex }));

		if (gameId && qId) {
			// Send the answer to the server
			await dispatch(playerAnswer({ gameId, answerIndex, questionId: qId }));
			// If you want to IMMEDIATELY request the next question (without seeing if you were correct/incorrect),
			// you can uncomment these lines.
			// But usually you'd wait for 'QuestionAnswer' event to confirm correctness first!
			/*
			setQ('');
			setChoices(null);
			setCorrectAnswer(-1);
			setSelectedAnswer(-1);
			dispatch(sendNewquestion({ gameId }));
			*/
		} else {
			console.error('gameId or qId is undefined');
		}
	};

	// Progress bar example
	useEffect(() => {
		const interval = setInterval(() => {
			setWidth(prev => {
				const numeric = parseFloat(prev);
				const newWidth = numeric - 100 / 15; // empty in 15 steps
				if (newWidth <= 0) {
					clearInterval(interval);
					return '0%';
				}
				return `${newWidth}%`;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [endTime]);

	return (
		<AcademyQuestionContainer>
			<img src='/img/academy/third_bg.webp' alt='' className='bg' />
			{loader ? (
				<h1>
					waiting for accepting by mached user
					<br />
					<br />
					be patient 😉
				</h1>
			) : (
				<>
					{/* Header */}
					<div className='header'>
						<div className='left'>
							{data && <img src={data?.assets.armor.iconCompleteUrl} alt='' className='figure' />}
							<p>{data?.displayName}</p>
							{data && <div className='score'>🎓 + {numberUtils.formatPriceWithDecimals(data?.score.education)}</div>}
						</div>
						<div className='right'>
							<img src={machedUser?.armor} alt='' className='figure' />
							<p>{machedUser ? machedUser.displayName : '...'}</p>
							<div className='score'>🎓 + {machedUser ? machedUser.score.education : '...'}</div>
						</div>
					</div>

					{/* History of answers: left is me, right is matched user */}
					<div className='answers_check'>
						<div className='ac_left'>
							{[...Array(5)].map((_, i) => {
								// userAnswers[i] is true, false, or undefined if we haven't gotten a result.
								const isCorrect = userAnswers[i + 1];

								let imgSrc = '/img/academy/status-q/q.webp'; // default
								if (isCorrect === true) {
									imgSrc = '/img/academy/status-q/correct.webp';
								} else if (isCorrect === false) {
									imgSrc = '/img/academy/status-q/wrong.webp';
								}
								// if null or undefined, remain q.webp
								return <img src={imgSrc} alt='' key={`q-left-${i}`} />;
							})}
						</div>

						<div className='ac_right'>
							{[...Array(5)].map((_, i) => {
								// machedUserAnswers[i] is true, false, or undefined
								const isCorrect = machedUserAnswers[i + 1];

								let imgSrc = '/img/academy/status-q/q.webp'; // default
								if (isCorrect === true) {
									imgSrc = '/img/academy/status-q/correct.webp';
								} else if (isCorrect === false) {
									imgSrc = '/img/academy/status-q/wrong.webp';
								}
								return <img src={imgSrc} alt='' key={`q-right-${i}`} />;
							})}
						</div>
					</div>

					{/* Current question */}
					<div className='question'>
						{q}
						<div className='title'>{topic === 'general' ? 'General' : 'Crypto'}</div>
						<div className='timer'>
							<div className='border'>
								<div className='filled' style={{ width }}></div>
							</div>
						</div>
					</div>

					{/* Current choices */}
					<div className='answer_box'>
						{/* If we do NOT yet know the correct answer => normal clickable choices */}
						{choices &&
							correctAnswer === -1 &&
							choices.map(choice => (
								<div key={`choice-${choice.index}`} onClick={() => onClickChoice(choice.index)} className='answer_item'>
									{choice.text}
								</div>
							))}

						{/* If we KNOW the correct answer => highlight them */}
						{choices &&
							correctAnswer !== -1 &&
							choices.map(choice => {
								let className = 'answer_item';
								if (choice.index === correctAnswer) {
									className = 'answer_item_correct';
								} else if (choice.index === selectedAnswer && choice.index !== correctAnswer) {
									className = 'answer_item_wrong';
								}
								return (
									<div key={`choice-${choice.index}`} className={className}>
										{choice.text}
									</div>
								);
							})}
					</div>
				</>
			)}
			{modal && me && (
				<ResultModal
					me={me}
					status={modal}
					setStatus={() => {
						dispatch(leaveAcademy());
						setModal(false);
					}}
				/>
			)}
		</AcademyQuestionContainer>
	);
};

export default AcademyQuestion;
