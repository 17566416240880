import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalAcceptContainer = styled.section(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		gap: 30px;
		height: 250px;
		button {
			width: 50%;
			color: #fff;
		}
	`,
);
