import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AcademyChooseTopicContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		background: #000d20;
		position: fixed;
		top: 0;
		inset-inline-start: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 100%;

		.bg {
			position: absolute;
			top: 0;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			width: 100%;
			object-fit: cover;
			aspect-ratio: 1170/2634;
		}
		.topic {
			position: absolute;
			top: 80vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			background: linear-gradient(112.59deg, rgba(255, 255, 255, 0.4) -39.46%, rgba(1, 13, 49, 0.4) 57.18%);
			padding: 9px 16px;
			border: 1px solid #f5c11d;
			border-radius: 28px;
			width: 60vw;
			text-align: center;
			font-size: 16px;
			font-weight: bold;
			line-height: 20px;
			letter-spacing: 0.08em;
		}
		.button_left {
			top: 96vw;
			position: absolute;
			inset-inline-start: 4vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 40vw;
			.icon {
				width: 60%;
				aspect-ratio: 101/161;
			}
			.input {
				height: 53px;
				background-repeat: no-repeat;
				background-position: center;
				background-origin: border-box;
				background-size: cover;
				background-image: url(/img/academy/input.webp);
				width: 100%;
				text-align: center;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 12px;
				margin-top: -20px;
				font-size: 18px;
				font-weight: bolder;
				line-height: 22.28px;
				letter-spacing: 0.08em;
			}
		}
		.button_right {
			top: 96vw;
			position: absolute;
			inset-inline-end: 4vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 40vw;
			.icon {
				width: 60%;
				aspect-ratio: 101/161;
			}
			.input {
				height: 53px;
				background-repeat: no-repeat;
				background-position: center;
				background-origin: border-box;
				background-size: cover;
				background-image: url(/img/academy/input.webp);
				width: 100%;
				text-align: center;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-bottom: 12px;
				margin-top: -20px;
				font-size: 18px;
				font-weight: bold;
				line-height: 22.28px;
				letter-spacing: 0.08em;
			}
		}
	`,
);
