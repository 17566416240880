import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MapContainer = styled.div(
	() => css`
		height: 100vh;
		position: relative;
		background: #000e56;
		.bg {
			position: fixed;
			top: 0;
			width: 100%;
		}
		.home {
			position: absolute;
			width: 95px;
			height: 90px;
			inset-inline-start: 56%;
			bottom: 35px;
			z-index: 10;
		}
	`,
);
