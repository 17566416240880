import { FC } from 'react';
import { PineyeContainer } from '../css/pineye.style';

interface IProps {
	img: string;
	pet: string | null;
}
const Pineye: FC<IProps> = ({ img, pet }) => {
	console.log(pet);
	return (
		<PineyeContainer>
			<img src={img} draggable={false} className={`armor ${pet !== null && pet !== undefined ? 'whit_pet' : ''}`} />
			{pet !== null && <img src={pet} draggable={false} className='pet' />}
		</PineyeContainer>
	);
};

export default Pineye;
