import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalResultContainer = styled.section(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		gap: 8px;
		height: 280px;
		position: relative;
		padding-top: 30px;
		.stars{
			text-align: center;
			position: absolute;
			top: -10px;
			inset-inline-start: 40%;
			width: 112px;
			height: 50px;
		}
		h2 {
			font-size: 17.55px;
			font-weight: bold;
			line-height: 21.72px;
			letter-spacing: 1.7px;
			text-align: center;
			position: absolute;
			top: 55px;
			inset-inline-start: 42%;
		}
		.reward_box {
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #e9c29f;
			font-size: 15.79px;
			font-weight: bold;
			line-height: 19.55px;
			text-align: center;
			padding-bottom: 10px;
			padding-top: 10px;
			border: 0.88px solid #ffffff;
			border-radius: 2.63px;
			width: 50%;
			height: 70px;
			justify-content: end;
			inset-inline-start: 10px;
			position: relative;
			gap: 13px;
			span {
				background: #002655;
				border-radius: 2.63px;
				box-shadow: 0px 3.51px 3.51px 0px #00000069;
				color: #fff;
				padding: 8px;
				width: 100%;
			}
		}
		.btns {
			display: flex;
			gap: 4px;
			width: 100%;
			justify-content: center;
			inset-inline-start: 10px;
			position: relative;
			button {
				width: 20%;
				border: 1px solid #5b5b5b;
			}
			.play {
			color: #fff;
			width: 30%;
		}
		}
		p {
			font-size: 11.41px;
			font-weight: bold;
			line-height: 14.12px;
			letter-spacing: 0.08em;
			color: #313131;
		}
		.topic {
			background-image: url(/img/academy/a_bg.webp);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			height: 27px;
			width: 100%;
			text-align: center;
			color: #fff;
			padding-top: 5px;
			font-size: 10.24px;
			font-weight: bold;
			line-height: 12.68px;
			letter-spacing: 0.08em;
		}
		
		@media (max-width: 345px) {
			padding-top: 38px;
			h2 {
				top: 65px;
				inset-inline-start: 40%;
			}
			.stars{ 
			top: 10px;
			inset-inline-start: 36%;
			 
		}
		}
	`,
);
