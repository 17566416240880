import { AcademyChooseTopicContainer } from './css';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { joinAcademy } from 'store/signalRActions';
import { useNavigate } from 'react-router-dom';

import { useGetAvatarsQuery } from 'store/query';

const AcademyChooseTopic = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { data } = useGetAvatarsQuery();

	const handleJoinAcademy = async (topic: string) => {
		await dispatch(joinAcademy({ topic, username: data?.displayName ?? '' }));
		navigate(`/academy/match/${topic}`);
	};
	return (
		<AcademyChooseTopicContainer>
			<img src='/img/academy/first_bg.webp' alt='' className='bg' />
			<div className='topic'>Choose a Topic</div>
			<div className='button_left' onClick={() => handleJoinAcademy('crypto')}>
				<img src='/img/academy/crypto.webp' alt='' className='icon' />
				<div className='input'>Crypto</div>
			</div>
			<div className='button_right' onClick={() => handleJoinAcademy('general')}>
				<img src='/img/academy/psycho.webp' alt='' className='icon' />
				<div className='input'>General</div>
			</div>
		</AcademyChooseTopicContainer>
	);
};

export default AcademyChooseTopic;
