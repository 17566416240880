import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { ModalResultContainer } from './css';
import ButtonNew from 'shared/components/button_new';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { updateAvatars, useGetAvatarsQuery } from 'store/query';
import { IPlayer } from 'store/types';

const ResultModal: FC<{ status: boolean; setStatus: (status: boolean) => void; me: IPlayer }> = ({ status, setStatus, me }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { data } = useGetAvatarsQuery();

	const onCloseClickHandler = () => {
		dispatch(
			updateAvatars({
				score: {
					food: +(data?.score.food ?? 0) + me.rewardWinner.food,
					diamond: +(data?.score.diamond ?? 0) + me.rewardWinner.diamond,
					education: +(data?.score.education ?? 0) + me.rewardWinner.education + me.score.education,
				},
			}),
		);
		onClose();
		navigate('/');
	};
	const onPlayClickHandler = () => {
		dispatch(
			updateAvatars({
				score: {
					food: +(data?.score.food ?? 0) + me.rewardWinner.food,
					diamond: +(data?.score.diamond ?? 0) + me.rewardWinner.diamond,
					education: +(data?.score.education ?? 0) + me.rewardWinner.education + me.score.education,
				},
			}),
		);
		onClose();
		navigate('/academy/choose');
	};

	return (
		<Modal
			disableHeader={true}
			status={_status}
			onClose={onCloseClickHandler}
			title=''
			useBackgroundForCloseModal={false}
			key={'modal-result-match'}
			backgroundImage='/img/academy/modal1_bg.webp'
		>
			{me && (
				<ModalResultContainer>
					<img src={me.isWinner ? '/img/academy/gold.webp' : '/img/academy/silver.webp'} className='stars' alt='' />
					<h2>{me?.isWinner ? 'You Win' : 'You Lose'}</h2>
					<div className='reward_box'>
						<span>Your Rewards</span>
						🎓 +{me?.score.education}
					</div>
					<div className='btns'>
						<ButtonNew variant='thirty' onClick={onCloseClickHandler}>
							Home
						</ButtonNew>
						<ButtonNew variant='primary' className='play' onClick={onPlayClickHandler}>
							Play
						</ButtonNew>
					</div>
				</ModalResultContainer>
			)}
		</Modal>
	);
};

export default ResultModal;
