import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AcademyMatchContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		background: #000d20;
		position: fixed;
		top: 0;
		inset-inline-start: 50%;
		transform: translateX(-50%);
		width: 100%;
		height: 100%;

		.bg {
			position: absolute;
			top: 0;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			width: 100%;
			object-fit: cover;
			aspect-ratio: 1170/2634;
		}
		.names {
			position: absolute;
			top: 90vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			background: #00105dcc;
			padding: 9px 16px;
			border: 1px solid #f5c11d;
			border-radius: 28px;
			width: 85vw;
			text-align: center;
			font-size: 14px;
			font-weight: bold;
			line-height: 20px;
			letter-spacing: 0.08em;
			display: flex;
			justify-content: space-between;
		}
		.counter {
			position: absolute;
			top: 80vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			background-repeat: no-repeat;
			background-position: center;
			background-origin: border-box;
			background-size: cover;
			background-image: url(/img/academy/counter_bg.webp);
			width: 30vw;
			height: 30vw;
			text-align: center;
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: Glass Antiqua;
			font-size: 53px;
			font-weight: 400;
			line-height: 55px;
			padding-bottom: 15px;
		}
		.figure {
			position: absolute;
			top: 103vw;
			inset-inline-start: 50%;
			transform: translateX(-50%);
			width: 75vw;
		}
	`,
);
